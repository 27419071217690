import React from "react"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import { pageTypes } from "../types"
import { getContentFromAllFiles } from "../utils/content"

const NotFoundPage = ({ data, pageContext: { locale } }) => {
  const { 
    title,
    description,
  } = getContentFromAllFiles(data, 'error');
  return (
    <Layout locale={locale} content={getContentFromAllFiles(data, 'site')}>
      <SEO title={`404 - ${title}`} lang={locale} path="/error/" />
      <Container className={styles.container}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{description}</h4>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query notFoundPageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              description
            }
            ...Site
          }
        }
      }
    }
  }
`

NotFoundPage.propTypes = pageTypes

export default NotFoundPage
